<template>
  <div class="password">
    <a-card title="修改头像">
      <a-form-model ref="form" :model="form.data">
        <a-row>
          <a-col :span="10" style="text-align: center;">
            <a-form-model-item prop="current" label="当前头像">
              <a-avatar shape="square" :size="78" :src="staff.avatar"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="4" style="text-align: center; font-size: 32px; padding-top: 48px;">
            <a-icon type="swap-right" />
          </a-col>
          <a-col :span="10" style="text-align: center;">
            <a-form-model-item prop="password" label="新头像">
              <van-uploader multiple :max-count="1" v-model="avatars" />
            </a-form-model-item>
          </a-col>
        </a-row>

      </a-form-model>
      <template slot="actions">
        <a-button type="primary" @click="onSubmit">提交上传</a-button>
      </template>
    </a-card>
  </div>
</template>

<script>
  export default {
    name: 'Avatar',
    data () {
      return {
        avatars:[],
        form: {
          data: {
          }
        }
      }
    },
    computed: {
      staff() {
        if (this.$store.state.staff) {
          return this.$store.state.staff;
        } else {
          return {
            avatar: '/img/avatar.png',
            name: '管理员'
          };
        }
      }
    },
    mounted() {
    },
    methods: {
      onReset() {
        this.$refs.form.resetFields();
      },
      onSubmit() {
        this.$refs['form'].validate(valid => {
          if (valid) {
            this.avatar();
          }
        });

      },
      avatar (){
        if (this.avatars.length == 0) {
          this.$alert('请选择新头像');
          return false;
        }

        let form = new FormData();
        form.append("avatar", this.avatars[0].file);

        this.$post('/avatar', form).then( res => {
          let ajax = res.data;
          if (ajax.code == 0) {

            if (ajax.data.staff) {
              this.$store.commit('staff', ajax.data.staff);
            }

            setTimeout(()=>{
              this.$router.go(-1);
            }, 500);

          }
        })
      }
    }
  }
</script>
<style lang="less" scoped>
  .password {
    margin: 80px auto; width: 560px;
  }
  .password h3 {
    /*text-align: center;*/
  }
  .password .box-body {
    padding: 30px;
  }
</style>
